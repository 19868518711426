import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import amplitude from 'amplitude-js';
import { Filter, Sort } from '@platform-ui/design-system/dist/types/Table';
import IdentityContext from '../../IdentityProvider/IdentityContext';
import { useIdentity } from '../../IdentityProvider';

const AMPLITUDE_KEY_MAP = {
  'www.zuora.com': '70d702bf4714255a28aecca9bb0fc68c',
  'eu.zuora.com': '70d702bf4714255a28aecca9bb0fc68c',
  'na.zuora.com': '70d702bf4714255a28aecca9bb0fc68c',
  'apisandbox.zuora.com': '75026068549b9aabfe51d20c859f8c36',
  'sandbox.eu.zuora.com': '75026068549b9aabfe51d20c859f8c36',
  'sandbox.na.zuora.com': '75026068549b9aabfe51d20c859f8c36',
  'test.zuora.com': '75026068549b9aabfe51d20c859f8c36',
  'test.eu.zuora.com': '75026068549b9aabfe51d20c859f8c36',
  'staging2.zuora.com': 'df990c09ea455f6305ea2391e1adcd5d',
  'cdn.zuora.com': 'df990c09ea455f6305ea2391e1adcd5d',
  localhost: 'df990c09ea455f6305ea2391e1adcd5d',
};

export const AmplitudeContext =
  React.createContext<amplitude.AmplitudeClient | null>(null);

export enum AmplitudeEvents {
  PRESS_BUTTON = 'press button',
  PAGE_VIEWED = 'page viewed',
  QUERY_LOADED = 'query loaded',
  POPOVER_OPEN = 'popover open',
  ADVANCED_SEARCH = 'advanced search page view',
  ADVANCED_SEARCH_FILTER = 'advanced search filter',
  ADVANCED_SEARCH_PAGINATION = 'advanced search pagination',
  BUILDER_ADD_FIELD = 'builder add field',
  BUILDER_REMOVE_FIELD = 'builder remove field',
  BUILDER_REORDER_FIELD = 'builder reorder field',
  BUILDER_SHOW_FIELD = 'builder show field',
  BUILDER_HIDE_FIELD = 'builder hide field',
  BUILDER_INCLUDE_CUSTOM_FIELDS_IN_SEARCH = 'builder include custom fields in search',
  BUILDER_EXCLUDE_CUSTOM_FIELDS_IN_SEARCH = 'builder exclude custom fields in search',
  BUILDER_DEFAULT_SEARCH_OPERATOR_STARTS_WITH = 'builder default search operator starts with',
  BUILDER_DEFAULT_SEARCH_OPERATOR_EQUALS = 'builder default search operator equals',
  BUILDER_DEFAULT_SEARCH_OPERATOR_CONTAINS = 'builder default search operator contains',
  PUBLISH_VIEW = 'builder publish view',
  BUILDER_SAVE = 'builder save',
  DISCARD_CHANGES = 'builder discard changes',
  UNPUBLISH_VIEW = 'builder unpublish view',
  REVERT = 'builder revert',
  CLICKED_BANNER = 'clicked banner',
  DISMISSED_BANNER = 'dismissed banner',
  SAW_BANNER = 'saw banner',
  EXPAND_COLLAPSE = 'expand/collapse',
  OPEN_UI_LABS_MENU = 'open ui labs menu',
  ENABLE_CUSTOM_VIEW = 'enable custom view',
  ENABLE_DEFAULT_VIEW = 'enable default view',
  CONFIGURE_PAGE = 'configure page',
  MANAGE_LAYOUTS = 'manage layouts',
  GLOBAL_TAX_HUB_NAV_TO_OLD_UI = 'global tax hub nav to old ui',
  BATCH_UPDATE_PRICE = 'batch update subscription price',
  PRESS_CHIP = 'press chip',
  NEW_CREATE_MEMO_OPEN = 'new create memo open',
  NEW_CREATE_MEMO_SAVE = 'new create memo save',
  USER_JOB_SELECT = 'user job select',
  USER_JOB_LAUNCH = 'user job launch',
  USER_JOB_SUBMIT = 'user job submit',
  USER_JOB_FOLLOW_UP = 'user job follow up',
  METERS_FLOW_DIRECTION = 'meters flow direction',
}

export const AmplitudeProvider = ({ children }: { children: React.ReactNode }) => {
  const hostname = window.location.hostname;
  const apiKey = AMPLITUDE_KEY_MAP[hostname] || '';
  const { identity, locale } = useIdentity();

  const {
    userId,
    platformRole,
    tenantId,
    tenantName,
    coreUserId,
    jobFunctions,
    entityId,
  } = identity ?? {};

  const instance = useMemo(() => {
    if (apiKey && identity) {
      const instance = amplitude.getInstance();
      const options: amplitude.Config = {
        includeUtm: true,
        includeReferrer: true,
        batchEvents: true,
        saveEvents: true,
        eventUploadPeriodMillis: 1500,
        eventUploadThreshold: 10,
      };

      // Workaround for amplitude automatically appending https to "apiEndpoint".
      if (window.location.protocol.includes('https')) {
        options.apiEndpoint = `${window.location.host}/platform/amplitude-proxy`;
      }

      instance.init(apiKey, coreUserId, options);
      instance.setUserProperties({
        platformAdmin: platformRole === 'ADMIN',
        tenantId: tenantId,
        tenantName,
        userId: coreUserId,
        locale: locale,
        jobFunctions: jobFunctions,
        entityId,
      });
      return instance;
    }
    return null;
  }, [
    userId,
    platformRole,
    tenantId,
    tenantName,
    coreUserId,
    jobFunctions,
    entityId,
    locale,
  ]);

  return (
    <AmplitudeContext.Provider value={instance}>
      {children}
    </AmplitudeContext.Provider>
  );
};

export const isSlowQuery = (error: Error): boolean => {
  return (
    // Timeout from Hawk
    error?.message?.includes('query took more than') ||
    // Timeout from legacy Gateway Proxy
    error?.message?.includes(`Cannot read property 'config' of undefined`) ||
    // Timeout from UI Router
    error?.message?.includes('The upstream server is timing out')
  );
};

export const useAmplitude = () => {
  const instance = useContext(AmplitudeContext);

  const queue = useRef<
    {
      eventType: AmplitudeEvents;
      eventDetails: Record<string, string>;
    }[]
  >([]).current;

  const getDefaultEventProps = () => {
    return {
      url: window.location.href,
    };
  };

  const logEvent = useCallback(
    (
      eventType: AmplitudeEvents,
      eventProperties: { [key: string]: string | number },
    ) => {
      const eventPayload = {
        eventType,
        eventDetails: {
          ...getDefaultEventProps(),
          ...eventProperties,
        },
      };

      if (!instance) {
        console.warn(
          `Amplitude: Client has not been initiated for current domain: ${window.location.hostname}. Log event added to the queue.`,
        );
        queue.push(eventPayload);
        return;
      }

      instance.logEvent(eventPayload.eventType, eventPayload.eventDetails);
    },
    [instance, queue],
  );

  const drainQueue = () => {
    if (queue.length > 0) {
      const { eventType, eventDetails } = queue[0];
      logEvent(eventType, eventDetails);
      queue.shift();
      drainQueue();
    }
  };

  useEffect(() => {
    if (instance) {
      drainQueue();
    }
  }, [instance]);

  const pressButton = (eventDetails: {
    actionLabel?: string;
    objectName?: string;
    columnHeaderLabel?: string;
    filterCriteria?: string;
    quickFilterLabel?: string;
  }) => {
    logEvent(AmplitudeEvents.PRESS_BUTTON, eventDetails);
  };

  const reportClickBanner = (eventDetails: { [key: string]: string }) => {
    logEvent(AmplitudeEvents.CLICKED_BANNER, eventDetails);
  };

  const reportDismissBanner = (eventDetails: { [key: string]: string }) => {
    logEvent(AmplitudeEvents.DISMISSED_BANNER, eventDetails);
  };

  const reportViewBanner = (eventDetails: { [key: string]: string }) => {
    logEvent(AmplitudeEvents.SAW_BANNER, eventDetails);
  };

  const logPageViewEvent = (eventDetails: {
    object: string;
    view: string;
    viewType?: string;
    screenHeight?: number;
    screenWidth?: number;
  }) => {
    logEvent(AmplitudeEvents.PAGE_VIEWED, eventDetails);
  };

  const popoverOpen = (eventDetails: {
    actionLabel?: string;
    componentId?: string;
  }) => {
    logEvent(AmplitudeEvents.POPOVER_OPEN, eventDetails);
  };

  const logToggleExpandCollapse = (eventDetails: {
    actionLabel?: string;
    object?: string;
    expanded?: boolean;
    componentId?: string;
  }) => {
    const details = {
      actionLabel: eventDetails?.actionLabel || '',
      object: eventDetails?.object || '',
      value: eventDetails?.expanded ? 'expand' : 'collapse',
      componentId: eventDetails?.componentId || '',
    };
    logEvent(AmplitudeEvents.EXPAND_COLLAPSE, details);
  };

  const logSearchPageView = (eventDetails: {
    objectType: string;
    searchTerm: string;
  }) => {
    logEvent(AmplitudeEvents.ADVANCED_SEARCH, eventDetails);
  };

  const logSearchFilterEvent = useCallback(
    (eventDetails: { objectType: string; filterProp: string }) => {
      logEvent(AmplitudeEvents.ADVANCED_SEARCH_FILTER, eventDetails);
    },
    [logEvent],
  );

  const logSearchPageTurning = (eventDetails: {
    objectType: string;
    pageSize: string;
    pageNumber: string;
  }) => {
    logEvent(AmplitudeEvents.ADVANCED_SEARCH_PAGINATION, eventDetails);
  };

  const logBuilderEvent = (
    event:
      | AmplitudeEvents.PUBLISH_VIEW
      | AmplitudeEvents.BUILDER_SAVE
      | AmplitudeEvents.DISCARD_CHANGES
      | AmplitudeEvents.BUILDER_ADD_FIELD
      | AmplitudeEvents.BUILDER_REMOVE_FIELD
      | AmplitudeEvents.BUILDER_REORDER_FIELD
      | AmplitudeEvents.BUILDER_HIDE_FIELD
      | AmplitudeEvents.BUILDER_SHOW_FIELD
      | AmplitudeEvents.BUILDER_EXCLUDE_CUSTOM_FIELDS_IN_SEARCH
      | AmplitudeEvents.BUILDER_INCLUDE_CUSTOM_FIELDS_IN_SEARCH
      | AmplitudeEvents.BUILDER_DEFAULT_SEARCH_OPERATOR_STARTS_WITH
      | AmplitudeEvents.BUILDER_DEFAULT_SEARCH_OPERATOR_EQUALS
      | AmplitudeEvents.BUILDER_DEFAULT_SEARCH_OPERATOR_CONTAINS
      | AmplitudeEvents.UNPUBLISH_VIEW
      | AmplitudeEvents.REVERT,
    requiredMeta: { template?: string },
    extraData: { [key: string]: string | undefined } = {},
  ) => {
    if (requiredMeta.template) {
      logEvent(event, { ...requiredMeta, ...extraData });
    }
  };

  const pressChip = (eventDetails: {
    chipLabel?: string;
    context?: string;
  }) => {
    logEvent(AmplitudeEvents.PRESS_CHIP, {
      ...eventDetails,
    });
  };

  return {
    logEvent,
    pressButton,
    logPageViewEvent,
    popoverOpen,
    reportClickBanner,
    reportDismissBanner,
    reportViewBanner,
    logToggleExpandCollapse,
    logQueryResults: ({
      dataSourceId,
      filter,
      orderBy,
      hasRecords,
      slowQuery = false,
      requestId = '',
      trackId = '',
    }: {
      dataSourceId: string;
      filter?: Filter | null;
      orderBy?: Sort;
      hasRecords: boolean;
      slowQuery: boolean;
      requestId?: string;
      trackId?: string;
    }) => {
      logEvent(AmplitudeEvents.QUERY_LOADED, {
        objectName: dataSourceId,
        filterCriteria: filter ? JSON.stringify(filter) : '',
        sortCriteria: orderBy ? JSON.stringify(orderBy) : '',
        hasRecords: `${hasRecords}`,
        slowQuery: `${slowQuery}`,
        requestId: requestId,
        trackId: trackId,
      });
    },
    logSearchPageView,
    logSearchFilterEvent,
    logSearchPageTurning,
    logBuilderEvent,
    pressChip,
  };
};