import { AlertColor, Box, CircularProgress } from '@mui/material';
import { Button, Card, Icon, Modal, ModalActions, ModalTitle, Table, ToastController, Typography, designTokens } from '@platform-ui/design-system';
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import Connect from '../Connect/Connect';
import CronBuilder from '../CronBuilder/CronBuilder';
import { timezones } from '../CronBuilder/constants';
import { IADState } from '../IntegrationApps/IntegrationAppDetails/state';
import { useStoreState } from '../Store';
import { Workflow } from './Execution';

interface NextRunModalProps {
  open: boolean;
  setOpenNextRunModal: Dispatch<SetStateAction<boolean>>;
  workflow: Workflow;
  setWorkflow: Dispatch<SetStateAction<Workflow>>;
}

interface NextRun {
  id: number;
  interval: string;
  timezone: string;
  next_run: string;
  scheduled_trigger: boolean;
  active_version_id: number;
}

const NEXT_RUN_COLUMNS = [
  {
    field: 'id',
    label: 'Id',
    sortable: true
  },
  {
    field: 'interval',
    label: 'Interval',
    sortable: false
  },
  {
    field: 'timezone',
    label: 'Time Zone',
    sortable: true
  },
  {
    field: 'next_run',
    label: 'Next Run',
    sortable: true
  }
];

export const NextRunModal: FC<NextRunModalProps> = ({
  open,
  setOpenNextRunModal,
  workflow,
  setWorkflow
}: NextRunModalProps) => {
  const state = useStoreState() as IADState;
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const memoWorkflow = useMemo(() => workflow, [workflow]);
  const [cron, setCron] = useState({
    timezone: 'UTC',
    expression: ['*', '*', '*', '*', '*', '*']
  });
  const [editCancelModal, setEditCancelModal] = useState({
    open: false,
    action: '',
    data: null
  });
  const [isSavingSchedule, setIsSavingSchedule] = useState(false);
  const [isCancellingSchedule, setIsCancellingSchedule] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success' as AlertColor
  });
  const connect: Connect = (window as any).connect;
  const workflowProxyPath = `/platform/gateway-proxy-v2`;

  const formatNextRun = (timestamp: number, tz: string) => {
    try {
      const tzVal = timezones.find(timezone => timezone.value === tz);
      return new Date(timestamp * 1000).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: tzVal ? tzVal.name : '',
        timeZoneName: 'shortOffset'
      });
    } catch (err) {
      Connect.log(err);
      return 'Next run parse failed';
    }
  };

  const getNextRuns = async (instances: NextRun[]) => {
    setLoading(true);
    try {
      const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
      const options = {
        method: 'POST',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf
        }),
        body: JSON.stringify({ runs: instances })
      };

      const response = await window.fetch(`${state.settingsUrl.replace('.json', '/workflow/next_runs')}`, options);

      if (!response.ok) throw Error(response.statusText);

      const nextRuns: any[] = await response.json();

      nextRuns.forEach(nextRun => {
        nextRun['next_run'] = formatNextRun(nextRun['next_run'], nextRun['timezone']);
      });

      setLoading(false);
      setRows(nextRuns);
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setRows([]);
    }
  };

  const cancelActionBody = (
    <Typography variant='titleL'>{`Are you sure you want to cancel the schedule for id ${editCancelModal.data?.id}`}</Typography>
  );

  const editActionBody = (
    <CronBuilder cron={cron} setCron={setCron} />
  );

  const cancelActionHeader = (
    <ModalTitle
      icon={<Icon body='delete_outline' color='error' />}
      dsOnClose={() => setEditCancelModal({ ...editCancelModal, open: false })}
    >
      Confirmation
    </ModalTitle>
  );

  const editActionHeader = (
    <ModalTitle
      dsOnClose={() => setEditCancelModal({ ...editCancelModal, open: false })}
    >
      Update Schedule
    </ModalTitle>
  );

  const cancelActionFooter = (
    <ModalActions>
      <Button variant='outlined' dsOnClick={() => setEditCancelModal({ ...editCancelModal, open: false })} body='No' />
      <Box sx={{m: 1, position: 'relative'}}>
        <Button
          variant='contained'
          disabled={isCancellingSchedule}
          dsOnClick={async () => {
            try {
              setIsCancellingSchedule(true);
              await patchWorkflow(editCancelModal.data?.id, editCancelModal.data?.active_version_id, false);
              setIsCancellingSchedule(false);
              setToast({
                show: true,
                msg: `Successfully cancelled the schedule for id ${editCancelModal.data?.id}`,
                severity: 'success'
              });
              setEditCancelModal({ ...editCancelModal, open: false });
            } catch (err) {
              Connect.log(err);
              setIsCancellingSchedule(false);
              setToast({
                show: true,
                msg: `Failed to cancel the schedule for id ${editCancelModal.data?.id}. Please try again later.`,
                severity: 'error'
              });
            }
          }}
          body='Yes'
        />
        {
          isCancellingSchedule &&
          <CircularProgress
            size={24}
            sx={{
              color: designTokens.colors.blue500,
              position: 'absolute',
              top: '50%',
              left: '35px',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        }
      </Box>
    </ModalActions>
  );

  const editActionFooter = (
    <ModalActions>
      {
          <Box sx={{m: 1, position: 'relative'}}>
          <Button
            disabled={isSavingSchedule}
            body='Save'
            endIcon='save'
            dsOnClick={async () => {
              try {
                setIsSavingSchedule(true);
                await patchWorkflow(editCancelModal.data?.id, editCancelModal.data?.active_version_id, true);
                setIsSavingSchedule(false);
                setToast({
                  show: true,
                  msg: `Successfully updated schedule for id ${editCancelModal.data?.id}`,
                  severity: 'success'
                });
                setEditCancelModal({ ...editCancelModal, open: false });
              } catch (err) {
                Connect.log(err);
                setIsSavingSchedule(false);
                setToast({
                  show: true,
                  msg: `Failed to update schedule for id ${editCancelModal.data?.id}. Please try again later.`,
                  severity: 'error'
                });
              }
            }}
          />
          {
            isSavingSchedule &&
            <CircularProgress
              size={20}
              sx={{
                color: designTokens.colors.blue500,
                position: 'absolute',
                top: '50%',
                left: '40px',
                marginTop: '-12px',
                marginLeft: '-12px'
              }}
            />
          }
        </Box>
      }
    </ModalActions>
  );

  const patchWorkflow = async (instanceId: number, activeVersionId: number, isScheduled: boolean) => {
    let response: Response;

    if (state.production) {
      // update workflow with schedule
      response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/versions/${activeVersionId}`,
        'PUT',
        {
          workflow: {
            scheduled_trigger: isScheduled,
            interval: isScheduled ? cron.expression.join(' ').trim() : '',
            timezone: isScheduled ? cron.timezone : ''
          }
        },
        { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal', 'Content-Type': 'application/json' }
      );
    } else {
      // update workflow with schedule
      const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
      const options = {
        method: 'PUT',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf
        }),
        body: JSON.stringify({
          workflow: {
            scheduled_trigger: isScheduled,
            interval: isScheduled ? cron.expression.join(' ').trim() : '',
            timezone: isScheduled ? cron.timezone : ''
          }
        })
      };

      response = await window.fetch(`${state.settingsUrl.replace('.json', `/workflow/instances/${activeVersionId}`)}`, options);
    }

    if (response && !response.ok) throw Error(response.statusText);

    setWorkflow({
      ...workflow,
      scheduled_trigger: isScheduled,
      interval: isScheduled ? cron.expression.join(' ').trim() : '',
      timezone: isScheduled ? cron.timezone : ''
    });
  }

  useEffect(() => {
    if (memoWorkflow.scheduled_trigger) {
      getNextRuns([{
        id: memoWorkflow.id,
        interval: memoWorkflow.interval,
        timezone: memoWorkflow.timezone,
        active_version_id: memoWorkflow.active_version_id
      } as any]);
    } else {
      setRows([]);
    }
  }, [memoWorkflow]);

  return (
    <>
      { toast.show && <ToastController severity={toast.severity} message={toast.msg} /> }
      <Modal
        id='next-run-modal'
        open={open}
        dsOnClose={() => setOpenNextRunModal(false)}
        body={
          <Card
            id='execution-next-runs'
            variant='outlined'
            body={
              <Table
                loading={loading}
                rows={rows}
                columns={NEXT_RUN_COLUMNS}
                uniqueKey='next-run-modal-table'
                showNoRowsMessage={rows?.length === 0}
                rowActions={[
                  {
                    icon: 'edit',
                    tooltip: 'Edit',
                    onClick: (e: { row: any }) => {
                      setEditCancelModal({ ...editCancelModal, open: true, action: 'edit', data: e.row });
                      setCron({
                        ...cron,
                        expression: e.row?.interval?.split(' ') || ['*', '*', '*', '*', '*', '*'],
                        timezone: e.row?.timezone || 'UTC'
                      });
                    }
                  },
                  {
                    icon: 'event_busy',
                    tooltip: 'Cancel',
                    onClick: (e: { row: any }) => {
                      setEditCancelModal({ ...editCancelModal, open: true, action: 'cancel', data: e.row });
                      setCron({
                        ...cron,
                        expression: e.row?.interval?.split(' ') || ['*', '*', '*', '*', '*', '*'],
                        timezone: e.row?.timezone || 'UTC'
                      });
                    }
                  }
                ]}
              />
            }
          />
        }
        header={
          <ModalTitle dsOnClose={() => setOpenNextRunModal(false)}>Scheduled Runs</ModalTitle>
        }
        footer={<ModalActions></ModalActions>}
        disableBackdropClick
        fullWidth
      />
      <Modal
        id='edit-cancel-scheduled-run-modal'
        open={editCancelModal.open}
        dsOnClose={() => setEditCancelModal({ ...editCancelModal, open: false })}
        body={editCancelModal.action === 'cancel' ? cancelActionBody : editActionBody}
        header={editCancelModal.action === 'cancel' ? cancelActionHeader : editActionHeader}
        footer={editCancelModal.action === 'cancel' ? cancelActionFooter : editActionFooter}
        disableBackdropClick
      />
    </>
  );
};